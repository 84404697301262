// extracted by mini-css-extract-plugin
export var linkhover = "dentist-module--linkhover--VhFnh";
export var bottom = "dentist-module--bottom--pFy-D";
export var right = "dentist-module--right--IkHTW";
export var callout = "dentist-module--callout--WOSPX";
export var link = "dentist-module--link--Rr71V";
export var top = "dentist-module--top--FH3GY";
export var left = "dentist-module--left--Ns+tL";
export var lefttop = "dentist-module--lefttop--SKSR2";
export var dentistinfo = "dentist-module--dentistinfo--kXVeb";
export var locationlink = "dentist-module--locationlink--YgDF1";
export var logolink = "dentist-module--logolink--NQTyd";
export var breadcrumbs = "dentist-module--breadcrumbs--hb+qF";
export var loclink = "dentist-module--loclink--ejmtE";
export var desktopbreadcrumbs = "dentist-module--desktopbreadcrumbs---YceF";
export var mobilebreadcrumbs = "dentist-module--mobilebreadcrumbs--g-m1e";
export var pagewrap = "dentist-module--pagewrap--bxhjk";
export var locationname = "dentist-module--locationname--AZb0t";
export var logo = "dentist-module--logo--6nZZg";
export var title = "dentist-module--title--e31tc";
export var dentistcontainer = "dentist-module--dentistcontainer--z-2Wd";
export var image = "dentist-module--image--IQXP0";
export var leftbottom = "dentist-module--leftbottom---9WzO";
export var bg = "dentist-module--bg--nrbC0";
export var bgxl = "dentist-module--bgxl--foGrd";
export var bgdesktop = "dentist-module--bgdesktop--prVsl";
export var bgtablet = "dentist-module--bgtablet--tNn-H";
export var heading = "dentist-module--heading--Q2EPA";
export var blurb = "dentist-module--blurb--FbMjq";
export var icon = "dentist-module--icon--O-+Bz";
export var insurance = "dentist-module--insurance--5uXYm";
export var insurancebottom = "dentist-module--insurancebottom--ptEhw";
export var insuranceimage = "dentist-module--insuranceimage--o0Hey";
export var specialties = "dentist-module--specialties--pUvEY";
export var list = "dentist-module--list--N-GpA";
export var listitem = "dentist-module--listitem--lcQVK";
export var dentistcontent = "dentist-module--dentistcontent--Brrgs";
export var contentleft = "dentist-module--contentleft--LiUfw";
export var gettoknow = "dentist-module--gettoknow--tkTxu";
export var calloutleft = "dentist-module--calloutleft--sdIGa";
export var smileassessmentsection = "dentist-module--smileassessmentsection--OPnn5";
export var smileassessmentsectionbg = "dentist-module--smileassessmentsectionbg--V+ueU";
export var button = "dentist-module--button--VL674";
export var sectionimage = "dentist-module--sectionimage--ntSTG";